import React from 'react'
import styled from '@emotion/styled'

const PanelsContainer = styled.div`
  background-color: white;
  width: 100%;
  overflow: hidden;
  min-height: 590px;
  @media (max-width: ${props => props.theme.screenWidth.medium}) {
    margin-bottom: 40px;
    position: relative;
  }
`

function Panels({ children, activeIndex }) {
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [activeIndex])

  return <PanelsContainer>{children[activeIndex]}</PanelsContainer>
}

// Transition Anims -- TBD
// function usePrevious(value) {
//   // The ref object is a generic container whose current property is mutable ...
//   // ... and can hold any value, similar to an instance property on a class
//   const ref = React.useRef()

//   // Store current value in ref
//   React.useEffect(() => {
//     ref.current = value
//   }, [value]) // Only re-run if value changes

//   // Return previous value (happens before update in useEffect above)
//   return ref.current
// }

// const previousIndex = usePrevious(activeIndex)

// const offset = previousIndex !== undefined ? activeIndex - previousIndex : 0

// const transitions = useTransition(activeIndex, p => p, {
//   from: {
//     transform: `translate3d(${offset * 100}%, 0, 0)`,
//   },
//   enter: {
//     transform: `translate3d(0%, 0, 0)`,
//   },
//   leave: {
//     transform: `translate3d(${-offset * 100}%, 0, 0)`,
//   },
// })

// {transitions.map(({ item, props, key }) => {
//   const element = children[item]
//   return (
//     <div className="panel" key={key}>
//       <animated.div style={props}>{element}</animated.div>
//     </div>
//   )
// })}

export default Panels
