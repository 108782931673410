import React from 'react'
import styled from '@emotion/styled'
import tailspin from '../../images/tail-spin.svg'

const FormButton = styled.button`
  height: 40px;
  min-width: 125px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  padding: 1px;
  background: linear-gradient(118deg, #601947, #315567);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), outline 0s;
  position: relative;
  overflow: hidden;
  border: none;

  div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 20px;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    font-size: 18px;
    font-family: ${props => props.theme.fonts.primaryRegular};
    color: #000;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    span {
      width: 100%;
      z-index: 2;
      position: relative;
    }
  }

  &.inactive {
    background: rgba(0, 0, 0, 0.45);

    div {
      color: rgba(0, 0, 0, 0.45);
    }
  }

  &.active {
    div {
      color: #000;
    }
    div::before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      height: 133px;
      width: 179px;
      transform: translate(-100%, 0);
      transition: 0.2s all ease;
      background: linear-gradient(to left, #315567, #601947);
      border-radius: 50%;
      z-index: 1;
    }
  }

  &.active:hover {
    div {
      color: #fff;
    }
    div::before {
      transform: translate(-50px, -30px);
    }
  }
`

const Spinner = styled.img`
  width: 18px;
  height: 18px;
  margin-top: 5px;
`

function Button({ children, onClick, loading, className, disabled }) {
  return (
    <FormButton
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={`${className} ${loading && 'loading'}`}
    >
      <div>
        <span>
          {loading ? <Spinner src={tailspin} alt="loading" /> : children}
        </span>
      </div>
    </FormButton>
  )
}

export default Button
