import React from 'react'
import * as yup from 'yup'
import { Form, Formik } from 'formik'
import { navigate } from 'gatsby'
import axios from 'axios'

import GeneralInformation from '../screens/GeneralInformation'
import SuccessScreen from '../screens/SuccessScreen'

import Panels from '../Panels'
import { FormContainer } from '../Containers'
import FormProgress from '../FormProgress'
import gtm from '../../../util/gtmEvent'

const initialValues = {
  fullName: '',
  email: '',
  referral: '',
  phoneNumber: '',
  description: '',
  newsletterSignup: false,
}

const validationSchema = yup.object().shape({
  fullName: yup.string().required('Please enter your full name'),
  email: yup
    .string()
    .email()
    .required('Please enter your email'),
  referral: yup.string(),
  phoneNumber: yup.string(),
  description: yup.string().required('Please enter your message'),
  newsletterSignup: yup.boolean(),
})

const GeneralForm = ({ formDetails }) => {
  const { title, inquiryType } = formDetails

  function handleSubmit(values, actions) {
    actions.setSubmitting(true)

    axios
      .post(`${process.env.GATSBY_LAMBDA_API_URL}/inquiries`, {
        ...values,
        inquiryType,
      })
      .then(response => {
        // Send event to GTM
        gtm(
          'Contact Us',
          // To have consistent grouping in GA Event view
          `${inquiryType.charAt(0).toUpperCase() +
            inquiryType.slice(1)} Inquiries`,
          'Contact Us',
          'Thank you screen'
        )
        actions.setSubmitting(false)
        actions.setStatus({ activeIndex: 1 })
      })
      .catch(err => {
        actions.setError('submit', err.message)
      })
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      initialStatus={{ activeIndex: 0 }}
      onSubmit={handleSubmit}
      render={({ status: { activeIndex }, submitForm, values }) => {
        function next() {
          submitForm()
        }

        function prev() {
          navigate('../contact')
        }

        const [firstName] = values.fullName.split(' ')

        return (
          <FormContainer>
            <Form>
              <Panels activeIndex={activeIndex}>
                <GeneralInformation title={title} />
                <SuccessScreen
                  usersName={firstName}
                  contactName={formDetails.contactName}
                  contactImage={`https:${formDetails.contactImage.file.url}`}
                  imageDescription={formDetails.contactImage.description}
                  calendarLink={formDetails.calendarLink}
                  inquiryType={formDetails.inquiryType}
                />
              </Panels>

              <FormProgress
                steps={2}
                activeIndex={activeIndex + 1}
                next={next}
                prev={prev}
              />
            </Form>
          </FormContainer>
        )
      }}
    />
  )
}

export default GeneralForm
