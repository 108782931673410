import React, { useState } from 'react'
import Lottie from 'react-lottie'
import styled from '@emotion/styled'
const animationData = require('./success.json')
import ContactSuccess from '../../images/contact-success.inline.svg'

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
}

const SuccessAnimationContainer = styled.div`
  .card {
    width: 200px;
    height: 200px;
    position: relative;

    transform-style: preserve-3d;
    backface-visibility: hidden;
  }

  .face {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 10px;
    text-align: center;
  }

  .front {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
  }

  .back {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotateY(180deg);
    z-index: 10;

    img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  @media (min-width: ${props => props.theme.screenWidth.medium}) {
    .card {
      width: 250px;
      height: 250px;
    }

    .back.face img {
      width: 185px;
      height: 185px;
    }
  }

  .flip-vertical-left {
    animation: flip-vertical-left 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
      both;
  }

  /**
* ----------------------------------------
* animation flip-vertical-left
* ----------------------------------------
*/
  @-webkit-keyframes flip-vertical-left {
    0% {
      transform: rotateY(0);
    }
    100% {
      transform: rotateY(-180deg);
    }
  }
  @keyframes flip-vertical-left {
    0% {
      transform: rotateY(0);
    }
    100% {
      transform: rotateY(-180deg);
    }
  }
`

function SuccessAnimation({ contactImage, imageDescription, onAnimationEnd }) {
  const [animating, setAnimating] = useState(true)

  const eventListeners = [
    {
      eventName: 'complete',
      callback: handleAnimationEnd,
    },
  ]

  function handleAnimationEnd() {
    setAnimating(false)
  }

  return (
    <SuccessAnimationContainer onAnimationEnd={onAnimationEnd}>
      <div className={`card ${!animating && 'flip-vertical-left'}`}>
        <div className="front face">
          <ContactSuccess
            width={450}
            height={450}
            onAnimationEnd={handleAnimationEnd}
          />
        </div>
        <div className="back face">
          <img src={contactImage} alt={imageDescription} />
        </div>
      </div>
    </SuccessAnimationContainer>
  )
}

export default SuccessAnimation
