import React, { useState } from 'react'
import styled from '@emotion/styled'
import Select, { components } from 'react-select'
import get from 'lodash.get'
import dropdownArrow from '../../images/dropdown@3x.png'

export const LabelTransformContainer = styled.div`
  margin: 0;
  border: 0;
  padding: 0;
  min-width: 0;
  display: inline-flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  margin: 15px 0;

  div.container {
    z-index: ${props => (props.type === 'select' ? 2 : 1)};
    position: relative;
    padding: 1px;
    border-color: transparent;
    border-radius: 5px;
    min-height: 50px;
    background-color: ${props =>
      props.error ? props.theme.colors.red : 'rgba(0, 0, 0, 0.45)'};
  }

  label {
    font-size: 18px;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.45);
    background-color: white;
    padding: 0 5px;
    z-index: 1;
    position: absolute;
    transform: translate(14px, 14px) scale(1);
    pointer-events: none;
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    transform-origin: top left;
    top: 0;
    left: 0;

    span {
      position: relative;
      top: 3px;
      margin: 2px 0;
      color: rgba(193, 28, 92, 0.9);
    }
  }

  input,
  textarea {
    height: 50px;
    width: 100%;
    border: 0;
    margin: 0;
    outline: none;
    padding: 9px 0;
    display: block;
    font-size: 18px;
    font-family: ${props => props.theme.fonts.secondary};
    border-radius: 5px;
  }

  textarea {
    position: relative;
    padding-top: 15px;
    min-height: 75px;
    height: 100%;
  }

  &.focused {
    div.container {
      padding: 1px;
      background: ${props =>
        props.error
          ? props.theme.colors.red
          : 'linear-gradient(82deg,#38215d,#c8266a 43%,#e579ac 66%,#051f33)'};
    }

    label {
      color: rgba(193, 28, 92, 0.9);
      transform: translate(14px, -10px) scale(0.75);
    }

    input,
    textarea {
      padding-left: 18px;
    }
  }

  .dropdown-arrow {
    width: 10px;
    height: 5px;
    position: relative;
    right: 5px;
    transition: transform 0.2s;
  }

  .dropdown-arrow.open {
    transition: transform 0.2s;
    transform: rotate(180deg);
  }

  .cl-select__indicator-separator {
    display: none;
  }

  .cl-select__value-container {
    padding: 0;
    font-size: 18px;
    font-family: ${props => props.theme.fonts.secondary};
    font-weight: normal;
    max-height: 50px;
  }

  .cl-select__single-value {
    top: 60%;
    color: #000;
    font-weight: normal;
  }

  .cl-select__control {
    padding-left: 18px;
    border: none;
    box-shadow: none;
  }

  .cl-select__option {
    font-size: 18px;
    color: #000;
    font-weight: normal;
  }

  .cl-select__option--is-focused,
  .cl-select__option--is-selected,
  .cl-select__option:hover {
    background-color: rgba(193, 28, 92, 0.1);
  }
`

function InputContainer({ field, error, label, type, required, children }) {
  const [focused, setFocus] = React.useState(false)

  function onFocus() {
    setFocus(true)
  }

  function onBlur(event) {
    field.onBlur(event)
    setFocus(false)
  }

  const active = focused || Boolean(field.value)

  return (
    <LabelTransformContainer
      error={error}
      className={active ? 'focused' : ''}
      type={type}
    >
      <div className="container">
        <label htmlFor={field.name}>
          {label} {required && <span>*</span>}
        </label>
        {React.cloneElement(children, { onFocus, onBlur })}
      </div>
    </LabelTransformContainer>
  )
}

const Container = styled.div`
  display: flex;
  flex-basis: 100%;
  position: relative;

  textarea {
    min-height: 76px;
    resize: none;
  }

  textarea.medium {
    height: 165px;
  }

  textarea.large {
    height: 250px;
  }

  @media (min-width: ${props => props.theme.screenWidth.medium}) {
    flex-basis: 48%;

    &.textarea {
      flex-basis: 100%;
      min-height: 50px;
      display: flex;
      align-items: center;
    }

    &.textarea.large {
      min-height: 330px;
    }
  }

  .error-message {
    height: 18px;
    display: block;
    font-family: ${props => props.theme.fonts.sourceCode};
    font-size: 12px;
    text-align: right;
    color: ${props => props.theme.colors.red};
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateY(-40px);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
  }

  .error-message.active {
    transform: translateY(5px);
  }
`

function TextArea({
  form,
  field,
  label,
  type,
  required,
  large,
  ariaLabel,
  ariaRequired,
}) {
  const error = get(form.errors, field.name)
  const touched = get(form.touched, field.name)
  const displayAll = form.status.displayAllErrors
  const errorActive = (error && touched) || (error && displayAll)

  return (
    <Container className="textarea">
      <InputContainer
        error={errorActive}
        field={field}
        label={label}
        type={type}
        required={required}
      >
        <textarea
          {...field}
          className={large && 'large'}
          id={field.name}
          aria-label={ariaLabel}
          aria-required={ariaRequired}
        />
      </InputContainer>
      <span className={`error-message ${errorActive && 'active'}`}>
        {error}
      </span>
    </Container>
  )
}

function TelInput({
  form,
  field,
  label,
  type,
  required,
  ariaLabel,
  ariaRequired,
}) {
  function handleChange(event) {
    // format telephone number -> 1234567890 -> 123-456-7890
    var number = event.target.value.replace(/[^0-9]/g, '')

    if (number.length >= 10) {
      number = number.substring(0, 10)
    }

    var o = ''
    for (var i = 0; i < number.length; i++) {
      o += number[i]
      if (i === 2 && number.length > 3) {
        o += '-'
      } else if (i === 5 && number.length > 6) {
        o += '-'
      }
    }

    form.setFieldValue(field.name, o)
  }

  return (
    <Container>
      <InputContainer
        label={label}
        type={type}
        required={required}
        field={field}
      >
        <input
          type="tel"
          {...field}
          onChange={handleChange}
          aria-label={ariaLabel}
          aria-required={ariaRequired}
        />
      </InputContainer>
    </Container>
  )
}

function TextInput({
  form,
  field,
  label,
  type,
  required,
  ariaLabel,
  ariaRequired,
}) {
  const error = get(form.errors, field.name)
  const touched = get(form.touched, field.name)
  const errorActive = error && touched

  return (
    <Container>
      <InputContainer
        error={errorActive}
        label={label}
        type={type}
        required={required}
        field={field}
      >
        <input
          type="text"
          {...field}
          id={field.name}
          aria-label={ariaLabel}
          aria-required={ariaRequired}
        />
      </InputContainer>
      <span className={`error-message ${errorActive && 'active'}`}>
        {error}
      </span>
    </Container>
  )
}

function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <img
        className={`dropdown-arrow ${props.selectProps.menuIsOpen && 'open'}`}
        src={dropdownArrow}
      />
    </components.DropdownIndicator>
  )
}

function SelectInput(props) {
  const [option, setOption] = useState()

  function handleChange(selectedOption) {
    setOption(selectedOption)
    props.form.setFieldValue(props.field.name, selectedOption.value)
  }

  const error = get(props.form.errors, props.field.name)
  // touch gets set on two different selectors w/ react-select, depending on interaction
  // this only matters for validation / displaying error messages
  const touched =
    get(props.form.touched, 'react-select-2-input') ||
    get(props.form.touched, props.field.name)

  const errorActive = error && touched

  return (
    <Container>
      <InputContainer {...props} error={errorActive}>
        <Select
          {...props}
          components={{ DropdownIndicator }}
          onChange={handleChange}
          value={option}
          placeholder=""
          className="cl-select"
          classNamePrefix="cl-select"
          aria-label={props.ariaLabel}
        />
      </InputContainer>
      <span className={`error-message ${errorActive && 'active'}`}>
        {error}
      </span>
    </Container>
  )
}

const CheckboxContainer = styled.div`
  display: flex;
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 18px;

  label {
    flex: 1;
  }

  input[type='checkbox'] {
    height: 16px;
    width: 16px;
    margin-right: 10px;
  }

  input[type='checkbox'] {
    -webkit-appearance: none;
    height: 18px;
    width: 18px;
    cursor: pointer;
    position: relative;
    border: solid 2px rgba(0, 0, 0, 0.25);
    background-color: transparent;
  }

  input[type='checkbox']:before,
  input[type='checkbox']:checked:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    line-height: 13px;
    text-align: center;
    color: black;
    content: '';
  }

  input[type='checkbox']:checked:before {
    content: '✔';
  }
`

function Checkbox({ field, id, label, form, ariaLabel }) {
  const value = form.values[field.name]
  return (
    <CheckboxContainer>
      <input
        {...field}
        id={id}
        type="checkbox"
        checked={value}
        aria-label={ariaLabel}
      />
      <label htmlFor={id}>{label}</label>
    </CheckboxContainer>
  )
}

export { TextArea, TextInput, SelectInput, Checkbox, TelInput }
