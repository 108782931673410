import styled from '@emotion/styled'

const PageContainer = styled.div`
  padding: 140px 30px 40px;
  margin: 0 auto;
  @media (min-width: ${props => props.theme.screenWidth.medium}) {
    display: flex;
    flex-wrap: wrap;
  }

  h1 {
    color: #fff;
    font-family: ${props => props.theme.fonts.primaryMedium};
    font-size: 21px;
    font-weight: 500;
    line-height: 1.43;
    text-align: center;
    @media (min-width: ${props => props.theme.screenWidth.medium}) {
      width: 100%;
      font-size: 40px;
    }
    @media (min-width: ${props => props.theme.screenWidth.large}) {
      font-size: 50px;
    }
  }
`

const FormContainer = styled(PageContainer)`
  background-color: #fff;
  margin: 0 auto;
  width: 100%;

  h1 {
    color: #000;
  }

  form {
    width: 100%;
    max-width: 580px;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  @media (min-width: ${props => props.theme.screenWidth.medium}) {
    form {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  @media (min-width: ${props => props.theme.screenWidth.large}) {
    form {
      max-width: 750px;
      padding: 0 80px;
    }
  }
`

export { PageContainer, FormContainer }
