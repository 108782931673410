import React from 'react'
import { Field } from 'formik'
import styled from '@emotion/styled'
import FormHeader from '../FormHeader'
import { TextInput, TextArea, Checkbox, TelInput } from '../../UI/FormikInputs'

const GeneralInformationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 90px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    margin-top: 30px;
  }
`

function GeneralInformation({ title }) {
  return (
    <React.Fragment>
      <FormHeader>{title}</FormHeader>
      <GeneralInformationContainer>
        <Field
          name="fullName"
          type="text"
          component={TextInput}
          label="Full name"
          required
          ariaLabel="Enter your full name"
          ariaRequired="true"
        />

        <Field
          name="email"
          type="email"
          component={TextInput}
          label="Email"
          required
          ariaLabel="Enter your email"
          ariaRequired="true"
        />

        <Field
          name="referral"
          type="referral"
          component={TextInput}
          label="How did you hear about us?"
          ariaLabel="How did you hear about us?"
        />

        <Field
          name="phoneNumber"
          type="tel"
          component={TelInput}
          label="Phone number"
          ariaLabel="Enter your phone number"
        />

        <Field
          name="description"
          type="text"
          component={TextArea}
          className="medium"
          label="Your message"
          required
          ariaLabel="Enter your message"
          ariaRequired="true"
        />

        <Field
          name="newsletterSignup"
          type="checkbox"
          component={Checkbox}
          label="Sign up to get free tips on how to build a better product from our newsletter."
          ariaLabel="Sign up to get free tips on how to build a better product from our newsletter"
        />
      </GeneralInformationContainer>
    </React.Fragment>
  )
}

export default GeneralInformation
