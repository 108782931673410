import styled from '@emotion/styled'

const FormHeader = styled.h1`
  font-family: ${props => props.theme.fonts.primaryRegular};
  font-size: 15px;
  font-weight: 500;
  color: #000000;
  flex-basis: 100%;
  position: relative;
  left: 0;
  right: 0;
  margin: 0;

  @media (min-width: ${props => props.theme.screenWidth.medium}) {
    font-size: 40px;
  }

  @media (min-width: ${props => props.theme.screenWidth.large}) {
    font-size: 50px;
    position: absolute;
  }
`

export default FormHeader
