import React from 'react'
import styled from '@emotion/styled'
import Button from './Button'

const ProgressContainer = styled.div`
  width: 100%;
`

const Pills = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 6px;
  margin-top: 80px;

  * + * {
    margin-left: 16px;
  }

  .pill {
    flex: 1;
    border-radius: 4px;
    background-color: #d8d8d8;
  }

  .pill.active {
    background-color: #000;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: 15px;

  @media (min-width: ${props => props.theme.screenWidth.medium}) {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
  }
`

function FormProgress({
  steps = 2,
  activeIndex = 0,
  next,
  prev,
  disableNext,
  isSubmitting,
}) {
  const isLastScreen = activeIndex === steps - 1

  if (activeIndex >= steps) {
    return null
  }

  function handlePillClick(index) {
    if (index < activeIndex) {
      prev()
    }

    if (index > activeIndex && !disableNext) {
      next()
    }
  }

  return (
    <ProgressContainer>
      <ButtonsContainer>
        <Button className="inactive" onClick={() => prev()}>
          Previous
        </Button>
        <Button
          loading={isLastScreen && isSubmitting}
          className={`${disableNext ? 'inactive' : 'active'}`}
          onClick={() => {
            next()
          }}
        >
          {isLastScreen ? 'Submit' : 'Next'}
        </Button>
      </ButtonsContainer>

      <Pills>
        {Array.from({ length: steps }, (_, index) => {
          return (
            <div
              key={index}
              onClick={() => handlePillClick(index)}
              className={`pill ${activeIndex === index && 'active'}`}
            />
          )
        })}
      </Pills>
    </ProgressContainer>
  )
}

export default FormProgress
