import React from 'react'
import styled from '@emotion/styled'
import SuccessAnimation from '../SuccessAnimation'
import productionLogo from '../../../images/production-logo-1@3x.png'

const SuccessContainer = styled.div`
  background-color: #fff;
  min-height: 500px;

  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 260px;
    margin: 0 auto;
  }

  .desktop {
    display: none;
  }

  h1,
  h1 strong {
    font-size: 21px;
    line-height: 1.33;
    font-family: ${props => props.theme.fonts.primaryRegular};
  }

  p,
  p strong {
    font-size: 24px;
    line-height: 26px;
    font-family: ${props => props.theme.fonts.secondary};
  }

  small {
    text-transform: uppercase;
    font-family: ${props => props.theme.fonts.sourceCode};
    font-size: 12px;
    letter-spacing: 0.9px;
  }

  @media (min-width: ${props => props.theme.screenWidth.medium}) {
    .mobile {
      display: none;
    }

    .desktop {
      display: block;
    }

    .inner {
      max-width: 620px;
    }

    h1,
    h1 strong {
      font-size: 36px;
      line-height: 46px;
    }

    p,
    p strong {
      font-size: 40px;
      line-height: 36px;
    }

    small {
      font-size: 16px;
    }
  }
`

function SuccessScreen({
  contactName,
  contactImage,
  imageDescription,
  usersName,
  calendarLink = '',
  inquiryType,
}) {
  const [flipAnimationEnded, setFlipAnimationEnded] = React.useState(false)

  function handleFlipEnd() {
    setFlipAnimationEnded(true)
  }

  let successType = ''

  switch (inquiryType) {
    case 'project':
      successType = 'new project'
      break
    case 'general':
      successType = 'inquiry'
      break
    case 'event':
      successType = 'partnership inquiry'
      break
    case 'media':
      successType = 'press inquiry'
  }

  return (
    <SuccessContainer>
      <div className="inner">
        <SuccessAnimation
          contactImage={contactImage}
          onAnimationEnd={handleFlipEnd}
          imageDescription={imageDescription}
        />

        <SlideInContainer>
          <div
            className={`slide-top-content ${flipAnimationEnded && 'slide-top'}`}
          >
            <h1 className="mobile">
              Thanks <strong>{`${usersName}`}!</strong>
            </h1>

            <h1 className="desktop">
              Thanks for your message <strong>{`${usersName}`}!</strong>
            </h1>

            <p>
              <strong>{contactName}</strong> will be in touch with you shortly
              regarding your {successType}.
            </p>

            <small>Want to talk sooner?</small>
            <a href={calendarLink} target="_blank" rel="noopener noreferrer">
              <img src={productionLogo} alt="Calendly" />
            </a>
          </div>
        </SlideInContainer>
      </div>
    </SuccessContainer>
  )
}

const SlideInContainer = styled.div`
  position: relative;
  overflow: hidden;

  text-align: center;

  img {
    display: block;
    margin: 0 auto;
    height: 40px;
    width: 40px;
    margin-top: 7px;
  }

  .slide-top-content {
    position: relative;
    top: 350px;
  }

  .slide-top {
    animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  /**
* ----------------------------------------
* animation slide-top
* ----------------------------------------
*/
  @-webkit-keyframes slide-top {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-350px);
    }
  }
  @keyframes slide-top {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-350px);
    }
  }
`

export default SuccessScreen
